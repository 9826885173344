import { ValidationError, useForm } from "@formspree/react";
import img from "./footerlogo.png";
import { useState } from "react";

export const Footer = () => {
  const [state, handleSubmit] = useForm("xzbqlykn");
  const [form, setForm] = useState("");

  return (
    <footer className="bg-footer text-white pt-12 pb-5 px-10 pl-16 z-20 w-full">
      <div className="flex flex-col items-start gap-y-8 lg:flex-row pb-8 w-full justify-between text-base font-normal lg:items-center">
        <img src={img} width={170} className="" alt="" />
        <div className="flex flex-col gap-y-1">
          <p>EGYPT</p>
          <p>Cairo</p>
          <p>Maadi, Autostrad Rd, Golden Gate Tower</p>
          <p className="mt-10">DUBAI</p>
        </div>
        <div className="flex flex-col gap-y-1">
          <p>CONTACT US</p>
          <a
            className="hover:text-red2"
            href="mailto:info@amkna-architects.com"
          >
            info@amkna-architects.com
          </a>
          <a className="hover:text-red2" href="tel:01145857333">
            01145857333
          </a>
          <a className="hover:text-red2" href="tel:01000555904">
            01000555904
          </a>
          <p className="mt-4 hover:text-red2">PRIVACY POLICY</p>
        </div>
        <div className="flex flex-col gap-y-0.5 lg:self-start">
          <a
            className="hover:text-red2"
            href="https://www.instagram.com/amkna_architects/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            INSTAGRAM
          </a>
          <a
            className="hover:text-red2"
            href="https://www.facebook.com/Amkna.Architects"
            target="_blank"
            rel="noreferrer"
          >
            FACEBOOK
          </a>
          <a
            className="hover:text-red2"
            href="https://www.linkedin.com/company/amkna"
            target="_blank"
            rel="noreferrer"
          >
            LINKEDIN
          </a>
          <a
            className="hover:text-red2"
            href="https://www.youtube.com/@AMKNAARCHITECTS"
            target="_blank"
            rel="noreferrer"
          >
            YOUTUBE
          </a>
        </div>
        <div className="flex flex-col gap-y-3 self-start">
          <p className="">Newsletter Sign-up</p>
          <form
            onSubmit={handleSubmit}
            method="post"
            className="flex flex-col gap-y-3"
          >
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Email address"
              onChange={(e) => setForm(e.target.value)}
              className="bg-footer rounded px-3 py-2 text-base border border-gray w-48"
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            {form !== "" && (
              <button
                type="submit"
                className="text-gray py-1 px-6 rounded-md border-2 border-gray self-center"
              >
                Send
              </button>
            )}
          </form>
        </div>
        <div className="lg:transform lg:-rotate-90 text-gray">
          <p
            className="hover:text-red2"
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            Back to top
          </p>
        </div>
      </div>
      <p className="text-center">
        All Rights Reserved To © 2023 Amkna Architects
      </p>
    </footer>
  );
};
