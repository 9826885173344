import { Header } from "./Header";
import Img1 from "./partners/1.png";
import Img3 from "./partners/2.png";
import Img4 from "./partners/3.png";
import { Footer } from "./Footer";
import Img2 from "./about.png";
import { useScroll } from "./util";

export const About = () => {
  useScroll();
  return (
    <div className="font-sans">
      <div className="absolute top-0 lg:left-1/2 lg:w-1/2 z-10">
        <img src={Img2} alt="" />
      </div>
      <Header />
      <div className="bg-red-500 mt-40 max-w-3xl p-8 lg:p-0 lg:ml-28 flex flex-col gap-y-7 text-center text-xl font-normal">
        <div className="text-justify">
          Amkna Architects is a dynamic innovative architecture and interior
          design firm dedicated to creating beautiful, functional, and
          sustainable spaces that inspire and delight based in Cairo, Egypt.
          Founded in 2017 by a team of highly experienced and talented Egyptian
          engineers, the company has quickly established a reputation for
          delivering high-quality projects on time and within budget.
        </div>
        <div className="text-justify">
          With a portfolio that spans residential, commercial, and institutional
          projects, our work is characterized by a commitment to excellence in
          design, materials, and construction. Whether you are building a new
          home, renovating an existing space, or developing a complex mixed-use
          project, we bring a wealth of experience and expertise to every
          project we undertake.
        </div>
        <div className="text-justify">
          At Amkna Architects, we believe that good design has the power to
          transform lives and communities. We are dedicated to using our skills
          and knowledge to create spaces that are not only beautiful and
          functional, but that are also environmentally responsible and
          sustainable.
        </div>
      </div>
      <div className="p-4 lg:p-0 lg:ml-16 mt-14 font-medium">
        <div className="flex flex-row items-center gap-x-6 mb-8">
          <div className="w-24 h-1 bg-red"></div>
          <div className="text-6xl text-red">Partners</div>
        </div>
        <div className="flex flex-col lg:flex-row gap-x-6 font-inter">
          {people.map((p) => (
            <div key={p.name}>
              <img src={p.img} alt={p.name} width={320} height={320} />
              <div className="text-2xl font-medium mt-7">{p.name}</div>
              <div className="text-xl font-light">{p.role}</div>
            </div>
          ))}
        </div>
        {/* <div className="flex flex-row items-center gap-x-6 mt-28 mb-8">
          <div className="w-24 h-1 bg-red"></div>
          <div className="text-6xl text-red">The Team</div>
        </div> */}
        <div className="flex flex-row items-center gap-x-6 mt-28 mb-10">
          <div className="w-24 h-1 bg-red"></div>
          <div className="text-6xl text-red">Core Values</div>
        </div>
        <div className="flex flex-col gap-y-8 max-w-5xl">
          {values.map((v) => (
            <div key={v.text}>
              <div className="text-2xl font-semibold">{v.title}</div>
              <div className="text-xl font-normal">{v.text}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-56 mb-28 font-normal text-3xl text-center">
        Let us help you bring your vision to life.
      </div>
      <Footer />
    </div>
  );
};

const people = [
  {
    img: Img1,
    name: "AHMED SAYED ALI ",
    role: "Co-Founder / Executive Manager",
  },
  {
    img: Img3,
    name: "AHMED KHALED",
    role: "Co-Founder / Design Director",
  },
  {
    img: Img4,
    name: "SHADY SHARAF",
    role: "Co-Founder / Construction Manager",
  },
];

const values = [
  {
    title: "COMMITMENT",
    text: "We bring an intense passion to our work and endeavor to build upon a meaningful legacy.",
  },
  {
    title: "DESIGN EXCELLENCE",
    text: "We believe in the power of good design to be transformative, regardless of a project’s type and scale: from house, to warehouse, to courthouse. Our body of work is authentic to its place, time and culture.",
  },
  {
    title: "DESIGN APPROACH",
    text: "We believe in an open-minded, collaborative approach to design, beginning every project with curiosity while remaining informed by our decades of experience.",
  },
  {
    title: "PROCESS",
    text: "Our architecture is rooted in making. Through our process, we explore how materials, structure, systems and craft come together to create inspired environments.",
  },
  {
    title: "COMMUNITY",
    text: "We aspire to make a positive impact on the lives of the people and the communities our buildings serve, building connections and bringing people together through engaging architecture.",
  },
  {
    title: "DIVERSITY",
    text: "We embrace and celebrate diversity in our organization. We commit to be thoughtful, honest, and open in our relationships with each other, our clients, collaborators, and peers.",
  },
];
