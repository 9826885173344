import Img2 from "./awards/1.png";
import Img3 from "./awards.png";
import Img4 from "./awards/3.png";
import Img5 from "./awards/2.png";
import Img6 from "./awards/4.jpg";
import Img7 from "./awards/5.png";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useScroll } from "./util";

export const Awards = () => {
  useScroll();
  return (
    <div className="font-sans w-full">
      <Header />
      <div className="w-full gap-x-4 lg:gap-x-20 flex flex-col gap-y-16 lg:flex-row mt-44 mx-auto justify-center items-center">
        {logos.map((l, i) => (
          <img
            key={i}
            src={l.img}
            alt=""
            className={"max-h-48 " + (i === 2 ? " max-w-xs" : "")}
          />
        ))}
      </div>
      <div className="mt-16 relative max-w-5xl">
        <img src={Img3} alt="" />
        <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-white">
          <p className="font-bold text-6xl lg:text-8xl font-sans">Awards</p>
          <p className="text-lg font-inter font-normal">
            Creating Architecture wonders
          </p>
        </div>
      </div>
      <div className="p-4 lg:p-0 lg:ml-28 mt-36 mb-16 flex flex-col gap-y-32 lg:gap-y-10">
        {awards.map((a) => (
          <Link
            key={a.name}
            to={"/projects/" + a.link.replaceAll(" ", "_")}
            className="flex flex-col lg:flex-row gap-x-40 items-center hover:text-red2"
          >
            <img src={a.img} alt={a.name} className="max-w-xs lg:max-w-sm" />
            <div className="flex flex-col gap-y-8">
              <div>
                <div className="text-xl">{a.name}</div>
                {a.texts &&
                  a.texts.map((aa) => <div className="text-sm">{aa}</div>)}
              </div>
              <div className="flex flex-row gap-x-8 items-center">
                {a.awards &&
                  a.awards.map((aa, i) => (
                    <img
                      src={aa}
                      alt=""
                      className={classNames("", {
                        "h-24": i === 0,
                        "h-16": i !== 0,
                      })}
                      key={aa}
                    />
                  ))}
              </div>
            </div>
          </Link>
        ))}
      </div>
      <Footer />
    </div>
  );
};

const awards = [
  {
    img: "/assets/projects/architectural/SEDHIOU CULTURAL CENTER/Cover.jpg",
    name: "SEDHIOU CULTURAL CENTER",
    awards: [Img2],
    link: "SEDHIOU CULTURAL CENTER",
  },
  {
    img: "/assets/projects/architectural/AL FALEEJ/Cover.jpg",
    name: "AL FALEEJ",
    texts: ["HONOR MENTION"],
    link: "AL FALEEJ",
  },
  {
    img: "/assets/projects/architectural/Science city/Cover.jpg",
    name: "SCIENCE CITY EGYPT",
    awards: [Img7, Img4],
    texts: ["TOP 20 PROJECT"],
    link: "Science city",
  },
  {
    img: "/assets/projects/architectural/Souq Zaafrana/Cover.jpg",
    name: "ZAFRANA SOUQ",
    texts: ["1 ST PRIZE 2015"],
    link: "Souq Zaafrana",
  },
];

const logos = [
  {
    img: Img2,
  },
  {
    img: Img5,
  },
  {
    img: Img4,
  },
  {
    img: Img6,
  },
];
