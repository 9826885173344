import { Link, useLocation } from "react-router-dom";
import img from "./Logo.png";
import { useLayoutEffect, useState } from "react";
import gsap from "gsap";
import classNames from "classnames";

export const Header = () => {
  const { pathname } = useLocation();
  const condition = sessionStorage.getItem("test") === "true";
  const [mobile, setMobile] = useState(false);

  useLayoutEffect(() => {
    if (pathname === "/" && !condition) {
      let tl = gsap.timeline(); //create the timeline
      tl.to(".header", { opacity: 1, duration: 1, delay: 4 });
    }
  }, [pathname, condition]);

  return (
    <header>
      <div
        className={classNames(
          "flex flex-row justify-between h-20 px-8 lg:px-32 uppercase font-medium text-xs text-white fixed top-0 left-0 w-full z-50 font-sans items-center",
          { "header lg:opacity-0": pathname === "/" && !condition }
        )}
      >
        <div className="absolute bg-black opacity-20 top-0 left-0 w-full h-full z-10"></div>
        <Link to="/" className="opacity-100 z-20">
          <img src={img} width={170} alt="Amkna" />
        </Link>
        {tabs.map((tab) => (
          <Link
            key={tab}
            to={"/" + tab}
            className="z-20 hidden md:block hover:text-red2"
          >
            {tab}
          </Link>
        ))}
        <button className="md:hidden z-20" onClick={() => setMobile(!mobile)}>
          <i className="fa-solid fa-bars"></i>
        </button>
        {mobile && (
          <div className="md:hidden flex flex-col gap-y-8 absolute top-20 right-0 p-8 rounded-lg z-20">
            <div className="absolute bg-black opacity-90 top-0 left-0 w-full h-full z-10"></div>
            {tabs.map((tab) => (
              <Link key={tab} to={"/" + tab} className="z-20">
                {tab}
              </Link>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

const tabs = ["projects", "awards", "about", "articles", "contact"];
