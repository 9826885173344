import { Header } from "./Header";
import { Footer } from "./Footer";
import { Circle, CircleFill, LeftArrow, RightArrow } from "./Icons";
import classNames from "classnames";
import { splitArray, useScroll } from "./util";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export const Projects = () => {
  useScroll();
  const { projectId: p } = useParams<{ projectId: string }>();
  const projectId = p?.replaceAll("_", " ");
  const [images, setImages] = useState<any>(null);
  const [selector, setSelector] = useState(0);
  const [tab, setTab] = useState("architectural");
  const [projects, setProjects] = useState(projectss);

  useEffect(() => {
    if (projectId) {
      const project = projectss.find((p) => p.title === projectId);
      if (project) setImages(project);
    }
  }, [projectId]);

  useEffect(() => {
    setProjects(projectss.filter((p) => (tab ? p.type === tab : true)));
  }, [tab]);

  const onClick = (project: Project) => {
    setImages(project);
  };

  useEffect(() => {
    if (images) window.scrollTo(0, 0);
  }, [images]);

  const Gallery = ({ images }: { images: Project }) => (
    <div
      className="w-full lg:h-screen bg-black z-30"
      onClick={() => {
        setImages(null);
        setSelector(0);
      }}
    >
      <div className="w-full py-32 flex h-full flex-row justify-center lg:justify-between items-center px-16">
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (selector > 0) setSelector(selector - 1);
            else setSelector(images.images - 1);
          }}
          className="hidden lg:block"
        >
          <LeftArrow />
        </button>
        <div className="flex flex-col items-center gap-y-3">
          <img
            src={`/assets/projects/${images.type}/${images.title}/${
              selector === images.images - 1 ? "Cover" : selector
            }.jpg`}
            alt=""
            className="max-w-xs lg:max-w-6xl max-h-g"
            onClick={(e) => e.stopPropagation()}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              if (currentTarget.src.includes("jpg"))
                currentTarget.src = `/assets/projects/${images.type}/${
                  images.title
                }/${selector === images.images - 1 ? "Cover" : selector}.png`;
              else
                currentTarget.src = `/assets/projects/${images.type}/${
                  images.title
                }/${selector === images.images - 1 ? "Cover" : selector}.jpeg`;
            }}
          />
          <div className="flex flex-row gap-x-1">
            {Array(images.images)
              .fill(0)
              .map((image, i) => (
                <>{i === selector ? <CircleFill /> : <Circle />}</>
              ))}
          </div>
          <div className="mt-16 flex flex-row w-full justify-between">
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (selector > 0) setSelector(selector - 1);
                else setSelector(images.images - 1);
              }}
              className="lg:hidden"
            >
              <LeftArrow />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (selector === images.images - 1) setSelector(0);
                else setSelector(selector + 1);
              }}
              className="lg:hidden"
            >
              <RightArrow />
            </button>
          </div>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (selector === images.images - 1) setSelector(0);
            else setSelector(selector + 1);
          }}
          className="hidden lg:block"
        >
          <RightArrow />
        </button>
      </div>
    </div>
  );

  const Image = ({
    project,
    className,
  }: {
    project?: Project;
    className?: string;
  }) => {
    if (!project) return null;
    const { title, place, brief, type } = project;
    return (
      <div
        className={"relative cursor-pointer group overflow-hidden " + className}
        onClick={() => onClick(project)}
      >
        <img
          src={`/assets/projects/${type}/${title}/Cover.jpg`}
          alt=""
          className={"relative w-full h-full object-cover z-10 "}
          loading="lazy"
        />
        <div className="absolute top-0 left-0 w-full h-full lg:hidden group-hover:block z-20">
          <div className="absolute hidden lg:block top-0 left-0 w-full h-full bg-black animate-overlay opacity-0"></div>
          <div className="absolute top-0 left-0 text-white flex flex-col h-full w-full p-2 lg:p-8">
            <div className="flex-grow">
              <p className="text-base lg:text-4xl font-oswald font-semibold overflow-hidden">
                {title}
              </p>
              {place && (
                <div className="hidden lg:flex flex-row items-center gap-x-1">
                  <div className="h-1 bg-white w-1/5"></div>
                  <p className="text-sm lg:text-xl font-oswald font-extralight">
                    {place}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-row justify-between w-full">
              {brief ? (
                <p className="hidden lg:block font-inter font-extralight text-xl w-1/2">
                  {brief}
                </p>
              ) : (
                <div className="flex-grow" />
              )}
              <div className="hidden lg:flex flex-row items-center gap-x-1">
                <div className="h-1 bg-white w-16"></div>
                <p className="text-xl font-oswald font-extralight">View more</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Layout = ({ projects }: { projects: Project[] }) => (
    <div className="flex flex-col justify-between gap-y-2 px-8">
      <Image project={projects[0]} className="w-full h-h-450 object-cover" />
      <div className="flex flex-col lg:flex-row justify-between gap-x-2 gap-y-2 lg:gap-y-0">
        <div className="flex flex-col justify-between gap-y-2 lg:w-1/2">
          <Image project={projects[1]} className="" />
          <div className="flex flex-col lg:flex-row justify-between gap-x-2 gap-y-2 lg:gap-y-0 max-h-96">
            <Image project={projects[2]} className="lg:w-1/2" />
            <Image project={projects[3]} className="lg:w-1/2" />
          </div>
        </div>
        <Image project={projects[4]} className="lg:w-1/2" />
      </div>
    </div>
  );

  return (
    <div className={classNames("font-sans", { "mt-24": images == null })}>
      <Header />
      {images != null ? (
        <Gallery images={images} />
      ) : (
        <>
          <div className="flex flex-col p-8 lg:p-0 lg:flex-row justify-center gap-y-4 pt-12 gap-x-8 mb-8">
            <div
              className={classNames(
                "px-8 py-2 border-2 border-gray rounded-md cursor-pointer hover:text-red2 hover:font-semibold",
                {
                  "bg-red2 text-white border-red2 hover:text-white":
                    tab === "architectural",
                }
              )}
              onClick={() => setTab("architectural")}
            >
              Architecture
            </div>
            <div
              className={classNames(
                "px-8 py-2 border-2 border-gray rounded-md cursor-pointer hover:text-red2 hover:font-semibold",
                {
                  "bg-red2 text-white border-red2 hover:text-white":
                    tab === "interior",
                }
              )}
              onClick={() => setTab("interior")}
            >
              Interior
            </div>
            <div
              className={classNames(
                "px-8 py-2 border-2 border-gray rounded-md cursor-pointer hover:text-red2 hover:font-semibold",
                {
                  "bg-red2 text-white border-red2 hover:text-white": tab === "",
                }
              )}
              onClick={() => setTab("")}
            >
              All
            </div>
          </div>
          <div className="flex flex-col gap-y-2 pb-8">
            {splitArray(projects).map((p) => (
              <Layout key={p[0].title} projects={p} />
            ))}
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

interface Project {
  title: string;
  place?: string;
  images: number;
  type: string;
  brief?: string;
}

const projectss: Project[] = [
  // {
  //   src: Img1,
  //   title: "AHMED SAYED ALI",
  //   place: "Executive Manage",
  //   brief: "amldmsld amdlmlsmdlsd skdlmslmd smdlmslmds",
  //   images: [Img1, Img1],
  // },
  // {
  //   src: Img1,
  //   title: "AL FALEEJ HONOR MENTION",
  //   place: "Executive Manage",
  //   images: [Img1],
  // },
  // {
  //   src: Img1,
  //   title: "AHMED SAYED ALI",
  //   place: "Executive Manage",
  //   brief: "amldmsld amdlmlsmdlsd skdlmslmd smdlmslmds",
  //   images: [Img1, Img1],
  // },
  // {
  //   src: Img1,
  //   title: "AHMED SAYED ALI",
  //   place: "Executive Manage",
  //   brief: "amldmsld amdlmlsmdlsd skdlmslmd smdlmslmds",
  //   images: [Img1, Img1],
  // },

  //arch

  {
    title: "SEDHIOU CULTURAL CENTER",
    place: "Senegal - Africa",
    images: 6,
    type: "architectural",
    brief:
      "An innovative and sustainable design incorporating traditional materials and techniques, providing spaces for cultural and educational activities with a focus on natural ventilation and lighting",
  },

  {
    title: "FIRST FARM OASIS",
    place: "Minya - Egypt",
    images: 6,
    type: "architectural",
    brief:
      "A mega poultry farm in Al Minya, designed with biosecurity measures and eco-friendly materials, emphasizing environmental sustainability and optimal space utilization",
  },
  {
    title: "Union Coop Hatta",
    place: "Dubai - UAE",
    images: 17,
    type: "architectural",
  },
  {
    title: "Souq Zaafrana",
    place: "Al Ain - UAE",
    images: 14,
    type: "architectural",
  },
  {
    title: "Teracess",
    place: "Dubai - UAE",
    images: 3,
    type: "architectural",
    brief:
      "A prestigious residential building in Dubai spanning 30,000 sqm. Offering luxurious living spaces, breathtaking views, and world-class amenities, it sets a new standard in modern, upscale urban living",
  },
  {
    title: "Helio Park",
    images: 8,
    type: "architectural",
  },

  {
    title: "Ladies Entertainment and business center",
    place: "Dubai - UAE",
    images: 5,
    type: "architectural",
  },
  {
    title: "DIKERNIS club",
    place: "Egypt",
    images: 20,
    type: "architectural",
  },
  {
    title: "City plaza mall",
    images: 9,
    type: "architectural",
  },
  {
    title: "Dubai 2050",
    place: "UAE",
    images: 6,
    type: "architectural",
  },

  {
    title: "EDU SUBLIME PARK",
    place: "Berlin - Germany",
    images: 10,
    type: "architectural",
  },
  {
    title: "JVC Tower",
    place: "Dubai - UAE",
    images: 3,
    type: "architectural",
  },

  {
    title: "Private villa 2",
    place: "Dubai - UAE",
    images: 2,
    type: "architectural",
  },

  {
    title: "Garden villa",
    images: 9,
    type: "architectural",
  },
  {
    title: "Dubai marina tower",
    place: "Dubai - UAE",
    images: 4,
    type: "architectural",
  },

  {
    title: "AL FALEEJ",
    place: "Ajman - UAE",
    images: 9,
    type: "architectural",
  },

  {
    title: "AL AIN GOLD SOUK",
    place: "Al Ain - UAE",
    images: 7,
    type: "architectural",
  },
  {
    title: "Science city",
    place: "6th Of October - Giza",
    images: 4,
    type: "architectural",
  },

  {
    title: "Private villa",
    place: "Dubai - UAE",
    images: 5,
    type: "architectural",
  },

  {
    title: "Albadiyah Mosque",
    place: "UAE",
    images: 5,
    type: "architectural",
  },

  //inter

  {
    title: "Zayed",
    place: "Zayed - 6th Of October",
    images: 12,
    type: "interior",
  },
  {
    title: "Hayati Residence Compound",
    place: "Fifth Settlement - Cairo",
    images: 26,
    type: "interior",
  },
  {
    title: "JAWHARA COMPOUND",
    place: "Jawhara Compound - New Capital",
    images: 7,
    type: "interior",
  },
  {
    title: "Meta Cafe",
    place: "Fifth Settlement - Cairo",
    images: 5,
    type: "interior",
  },
  {
    title: "EL ESKAN COMPOUND",
    place: "El Eskan Compound - 6th Of October",
    images: 17,
    type: "interior",
  },

  {
    title: "Palace",
    place: "Sharjah - UAE",
    images: 9,
    type: "interior",
  },
  {
    title: "Private villa 3",
    place: "Dubai - UAE",
    images: 15,
    type: "interior",
  },
];
