import {
  PrismicImage,
  PrismicRichText,
  useAllPrismicDocumentsByType,
} from "@prismicio/react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Link } from "react-router-dom";
import { useScroll } from "./util";

export const Articles = () => {
  useScroll();
  const [documents] = useAllPrismicDocumentsByType("article");
  console.log(documents);

  return (
    <div className="mt-40 font-sans">
      <Header />
      <div className="flex flex-col items-center mt-32">
        <div>
          <div className="text-6xl lg:text-9xl font-oswald font-normal text-red3">
            The Articles
          </div>
          <div className="text-base lg:text-xl font-sans font-normal text-red3 ml-4">
            AMKNA’S RESOURCES FOR YOU
          </div>
        </div>
        <div className="mt-32 flex flex-row flex-wrap lg:gap-x-16 gap-y-16 p-8 justify-start w-full">
          {documents?.map(({ data, id }) => (
            <Link
              to={"/article/" + id}
              className=" max-w-xs lg:max-w-sm shadow-article"
            >
              <PrismicImage
                field={data.image}
                className="h-64 object-cover w-full"
              />
              <div className="p-8">
                <div className="underline text-lg font-semibold hover:text-red2">
                  <PrismicRichText field={data.title} />
                </div>
                <div className="mt-8">{data.body[0].text.slice(0, 400)}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
