import { PrismicRichText, usePrismicDocumentByID } from "@prismicio/react";
import { useParams } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { useScroll } from "./util";

export const Article = () => {
  useScroll();
  const { articleId } = useParams<{ articleId: string }>();
  const [document] = usePrismicDocumentByID(articleId || "");
  if (!document) return null;

  return (
    <div>
      <Header />
      <div className="article mt-32 flex flex-col items-center px-2 lg:px-10 gap-y-16">
        <div className="font-oswald font-normal text-3xl lg:text-6xl text-center w-2/3 text-red3">
          <PrismicRichText field={document.data.title} />
        </div>
        <img src={document.data.image.url} alt="" className="" />
        <div className="p-4 lg:p-8">
          <PrismicRichText field={document.data.body} />
        </div>
      </div>
      <Footer />
    </div>
  );
};
