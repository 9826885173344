export const RightArrow = () => (
  <svg
    width="59"
    height="59"
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.3972 0C13.1994 0 0 13.1994 0 29.3972C0 45.5951 13.1994 58.7945 29.3972 58.7945C45.5951 58.7945 58.7945 45.5951 58.7945 29.3972C58.7945 13.1994 45.5951 0 29.3972 0ZM37.5991 30.9553L27.2218 41.3325C26.7809 41.7735 26.2223 41.9793 25.6638 41.9793C25.1052 41.9793 24.5467 41.7735 24.1057 41.3325C23.2532 40.48 23.2532 39.0689 24.1057 38.2164L32.9249 29.3972L24.1057 20.5781C23.2532 19.7255 23.2532 18.3145 24.1057 17.462C24.9583 16.6094 26.3693 16.6094 27.2218 17.462L37.5991 27.8392C38.481 28.6917 38.481 30.1028 37.5991 30.9553Z"
      fill="#4F4F4F"
    />
  </svg>
);

export const LeftArrow = () => (
  <svg
    width="59"
    height="59"
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.395 0C13.1984 0 0 13.1984 0 29.395C0 45.5916 13.1984 58.79 29.395 58.79C45.5916 58.79 58.79 45.5916 58.79 29.395C58.79 13.1984 45.5916 0 29.395 0ZM34.6567 38.2135C35.5092 39.066 35.5092 40.4769 34.6567 41.3294C34.2158 41.7703 33.6573 41.9761 33.0988 41.9761C32.5403 41.9761 31.9818 41.7703 31.5408 41.3294L21.1644 30.9529C20.3119 30.1005 20.3119 28.6895 21.1644 27.8371L31.5408 17.4606C32.3933 16.6082 33.8043 16.6082 34.6567 17.4606C35.5092 18.3131 35.5092 19.724 34.6567 20.5765L25.8382 29.395L34.6567 38.2135Z"
      fill="#4F4F4F"
    />
  </svg>
);

export const Circle = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25 10C2.775 10 0.75 7.975 0.75 5.5C0.75 3.025 2.775 1 5.25 1C7.725 1 9.75 3.025 9.75 5.5C9.75 7.975 7.725 10 5.25 10Z"
      stroke="white"
    />
  </svg>
);

export const CircleFill = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25 10C2.775 10 0.75 7.975 0.75 5.5C0.75 3.025 2.775 1 5.25 1C7.725 1 9.75 3.025 9.75 5.5C9.75 7.975 7.725 10 5.25 10Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

export const Close = () => <></>;
