import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useLayoutEffect, useRef } from "react";
import { Footer } from "./Footer";
import P1 from "./landing/1.png";
import P2 from "./landing/2.png";
import P3 from "./landing/3.png";
import P4 from "./landing/0.jpg";
import P6 from "./landing/6.png";
import { Link } from "react-router-dom";
import img from "./Logo.png";
import Img3 from "./awards/1.png";
import Img4 from "./awards/3.png";
import Img5 from "./awards/2.png";
import Img6 from "./awards/4.jpg";
import { Header } from "./Header";
import { useScroll } from "./util";
import classNames from "classnames";

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  useScroll();
  const comp = useRef(); // create a ref for the root level element (for scoping)
  const condition = sessionStorage.getItem("test") === "true";

  useLayoutEffect(() => {
    if (window.innerWidth < 1024) return;
    if (!condition) {
      let tl = gsap.timeline(); //create the timeline
      tl.to(".logo", { opacity: 1, duration: 2, delay: 1 })
        .to(".logo", { opacity: 0, duration: 1 })
        .to(".screen", { opacity: 0, duration: 1, delay: 0 });
    }

    // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
    let ctx = gsap.context(() => {
      let panels: any[] = gsap.utils.toArray(".panel");
      // we'll create a ScrollTrigger for each panel just to track when each panel's top hits the top of the viewport (we only need this for snapping)
      let tops = panels.map((panel) =>
        ScrollTrigger.create({ trigger: panel, start: "top top" })
      );

      panels.forEach((panel, i) => {
        ScrollTrigger.create({
          trigger: panel,
          start: () =>
            panel.offsetHeight < window.innerHeight
              ? "top top"
              : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
          pin: true,
          pinSpacing: false,
        });
      });

      ScrollTrigger.create({
        snap: {
          snapTo: (progress: any, self: any) => {
            let panelStarts = tops.map((st) => st.start), // an Array of all the starting scroll positions. We do this on each scroll to make sure it's totally responsive. Starting positions may change when the user resizes the viewport
              snapScroll = gsap.utils.snap(panelStarts, self.scroll()); // find the closest one
            return gsap.utils.normalize(
              0,
              ScrollTrigger.maxScroll(window),
              snapScroll
            ); // snapping requires a progress value, so convert the scroll position into a normalized progress value between 0 and 1
          },
          duration: 0.5,
        } as any,
      });
    }, comp); // <- IMPORTANT! Scopes selector text

    sessionStorage.setItem("test", "true");

    return () => ctx.revert(); // cleanup
  }, [condition]);

  return (
    <>
      {!condition && (
        <div className="hidden lg:flex absolute top-0 left-0 z-20 w-full h-screen bg-black justify-center items-center screen">
          <img src={img} width={170} alt="Amkna" className="logo opacity-0" />
        </div>
      )}
      <Header />
      <div
        className="flex flex-col lg:block font-sans w-full h-screen z-10 mt-20 lg:mt-0"
        ref={comp as any}
      >
        {images.map(({ src, title, subtitle, link }, i) => (
          <div className="relative w-full lg:min-h-full panel" key={title}>
            <img
              key={title}
              src={src}
              className="w-full object cover z-20"
              alt={title}
            />
            <div
              className={classNames(
                "absolute inset-0 flex flex-col items-center justify-center text-white z-40",
                {
                  "pb-16 lg:pb-0": i === 4,
                }
              )}
            >
              <p
                className={classNames("text-2xl lg:text-7xl font-medium", {
                  "lg:pt-96": i === 4,
                })}
              >
                {title}
              </p>
              <p className="text-base lg:text-3xl font-light mt-4 mb-6">
                {subtitle}
              </p>
              <Link
                to={"/projects/" + link.replaceAll(" ", "_")}
                className="text-xs lg:text-base font-medium border border-white px-4 p-2 cursor-pointer hover:bg-white hover:text-red2"
              >
                Learn more
              </Link>
            </div>
          </div>
        ))}
        <div className="panel w-full h-full bg-footer flex flex-row justify-center items-center text-white">
          <p className="max-w-3xl text-xl lg:text-4xl text-justify font-sans p-8">
            <span className="text-6xl">W</span>elcome to Amkna Architects, a
            dynamic innovative architecture and interior design firm dedicated
            to creating beautiful, functional, and sustainable spaces that
            inspire and delight.
          </p>
        </div>
        <div className="panel w-full h-full bg-white flex flex-col">
          <iframe
            src="https://momento360.com/e/u/8883660038c44e278f0335c8e2029f2b?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true"
            height={600}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="w-full"
            title="map"
          ></iframe>
          <div className="justify-between flex flex-row items-center flex-grow gap-x-4 py-4 lg:gap-x-12 mx-auto">
            {logos.map((l) => (
              <img src={l.img} className="w-16 lg:w-40" alt="" key={l.img} />
            ))}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default App;

const images = [
  {
    src: P4,
    title: "First farm Oasis",
    subtitle: "Minya, Egypt",
    link: "FIRST FARM OASIS",
  },
  {
    src: P1,
    title: "The Terraces Residential Building",
    subtitle: "Dubai, UAE",
    link: "Teracess",
  },
  {
    src: P2,
    title: "Union Coop",
    subtitle: "Hatta Area, UAE",
    link: "Union Coop Hatta",
  },
  {
    src: P3,
    title: "Private Villa",
    subtitle: "Cairo, Egypt",
    link: "Private villa",
  },
  {
    src: P6,
    title: "SEDHIOU CULTURAL CENTER",
    subtitle: "Senegal, Africa",
    link: "SEDHIOU CULTURAL CENTER",
  },
];

const logos = [
  {
    img: Img3,
  },
  {
    img: Img5,
  },
  {
    img: Img4,
  },
  {
    img: Img6,
  },
];
