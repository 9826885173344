import { useEffect } from "react";

export const splitArray = <T>(arr: T[]): T[][] => {
  const result: T[][] = [];
  for (let i = 0; i < arr.length; i += 5) {
    result.push(arr.slice(i, i + 5));
  }
  return result;
};

export const useScroll = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};
