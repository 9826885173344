import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { About } from "./About";
import { Projects } from "./Projects";
import { Awards } from "./Awards";
import { Articles } from "./Articles";
import { Contact } from "./Contact";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import { Article } from "./Article";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/projects/:projectId",
    element: <Projects />,
  },
  {
    path: "/awards",
    element: <Awards />,
  },
  {
    path: "/articles",
    element: <Articles />,
  },
  {
    path: "/article/:articleId",
    element: <Article />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

root.render(
  <React.StrictMode>
    <PrismicProvider client={client}>
      <RouterProvider router={router} />
    </PrismicProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
