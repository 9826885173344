import { ValidationError, useForm } from "@formspree/react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { useState } from "react";
import { useScroll } from "./util";

export const Contact = () => {
  useScroll();
  const [state, handleSubmit] = useForm("xqkovwgj");
  const [form, setForm] = useState({ name: "", message: "" });

  return (
    <div className="w-full h-full">
      <Header />
      <div className="mt-44 font-sans w-full">
        <div className="absolute top-0 left-0 w-full min-h-ss h-screen bg-footer z-10"></div>
        <div className="flex justify-center items-center flex-col text-white font-sans gap-y-16 z-20">
          <div className="font-oswald font-medium text-6xl lg:text-8xl z-20">
            CONTACT US
          </div>
          <div className="flex flex-col gap-y-28">
            <div className="flex flex-col lg:flex-row justify-between z-20 lg:h-full">
              <div className="flex flex-col justify-between p-8 lg:p-0 gap-y-4 lg:gap-y-0">
                <div>
                  <div className="font-normal text-3xl">Mobile:</div>
                  <a
                    className="text-xl block hover:text-red2"
                    href="tel:01145857333"
                  >
                    01145857333
                  </a>
                  <a className="text-xl hover:text-red2" href="tel:01000555904">
                    01000555904
                  </a>
                </div>
                <div>
                  <div className="font-normal text-3xl">Address:</div>
                  <div className="text-xl">Cairo</div>
                  <div className="text-xl">Maadi, Autostrad Rd, Golden Gate Tower</div>
                </div>
                <div>
                  <div className="font-normal text-3xl">Operating Hours:</div>
                  <div className="text-xl">Saturday to Thursday</div>
                </div>
                <div>
                  <div className="font-normal text-3xl">Email:</div>
                  <a
                    className="text-xl block hover:text-red2"
                    href="mailto:info@amkna-architects.com"
                  >
                    info@amkna-architects.com
                  </a>
                  <a
                    className="text-xl hover:text-red2"
                    href="mailto:hr@amkna-architects.com"
                  >
                    hr@amkna-architects.com
                  </a>
                </div>
              </div>
              <div className="bg-white px-10 py-5 text-black flex flex-col gap-y-10 rounded-sm">
                <div className="font-oswald font-medium text-2xl text-center">
                  Talk with our sales team
                </div>
                {state.succeeded ? (
                  <div className="flex justify-center">Message sent</div>
                ) : (
                  <form onSubmit={handleSubmit} method="post">
                    <div className="flex flex-col gap-y-4">
                      <div>
                        <div>Name*</div>
                        <input
                          className="border-b-2 border-gray w-80"
                          onChange={(e) =>
                            setForm({ ...form, name: e.target.value })
                          }
                        />
                      </div>
                      <div>
                        <div>
                          <label htmlFor="email">Email*</label>
                        </div>
                        <input
                          id="email"
                          type="email"
                          name="email"
                          className="border-b-2 border-gray w-80"
                        />
                        <ValidationError
                          prefix="Email"
                          field="email"
                          errors={state.errors}
                        />
                      </div>
                      <div>
                        <div>Phone</div>
                        <input className="border-b-2 border-gray w-80" />
                      </div>
                      <div>
                        <div>Message*</div>
                        <textarea
                          id="message"
                          name="message"
                          className="border-b-2 border-gray w-80"
                          onChange={(e) =>
                            setForm({ ...form, message: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="flex row justify-center">
                      <button
                        type="submit"
                        disabled={
                          state.submitting ||
                          form.name === "" ||
                          form.message === ""
                        }
                        className="text-gray py-1 px-6 mt-12 rounded-md border-2 border-grey self-center"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.4849793902886!2d31.311585600000008!3d29.965489299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145819b7af08c5c7%3A0x7d57999452892830!2sAmkna.Architects!5e0!3m2!1sen!2seg!4v1684797262886!5m2!1sen!2seg"
              width="1000"
              height="450"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="rounded-xl z-20 max-w-xs lg:max-w-screen-lg mx-auto"
              title="map"
            ></iframe>
          </div>
          <a
            href="https://www.google.com/maps/place/Amkna.Architects/@29.9654893,31.3115856,17z/data=!3m1!4b1!4m6!3m5!1s0x145819b7af08c5c7:0x7d57999452892830!8m2!3d29.9654893!4d31.3115856!16s%2Fg%2F11kpn79w76?hl=en-EG"
            className="bg-white text-gray py-8 px-14 lg:px-28 rounded-md font-sans text-xl border-2 border-grey mb-12 hover:bg-red2 hover:text-white"
            target="_blank"
            rel="noreferrer"
          >
            Open in google maps
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
